<script>
  import { createEventDispatcher, tick } from "svelte";

  export let placeholderText = "*Your Number";
  export let isValid = false;
  export let phoneNumber = "";
  export let rtl = false;
  export let includeErrors = false;
  
  let dispatch = createEventDispatcher();

  async function handleValidation() {
    await tick();
    // is longer than 2
    phoneNumber = phoneNumber.replace(/[^0-9.]/g, "");
    isValid = phoneNumber.length > 0;
    dispatch("change", { isValid, phoneNumber });
  }
</script>

<div class="input-wrapper">
  <input type="text"
    class={`${(rtl) ? 'rtl text-sm' : 'text-sm'} p-2`}
    on:input={handleValidation}
    on:change={handleValidation}
    placeholder={placeholderText}
    bind:value={phoneNumber}
  />
  {#if includeErrors}
    <div class="error-wrapper">
      {#if !isValid && phoneNumber.length > 2}
        Please include a valid phone number.
      {/if}
    </div>
  {/if}
</div>


<style>
  .input-wrapper {
    width: 100%;
  }

  input[type="text"] {
    width: 100%;
    border: none;
    height: 32px;
    background-color: white;
    border: 1px solid #c3c3c3;
  }

  .error-wrapper {
    width: 100%;
    height: 25px;
    color: #e20303;
    font-size: 12px;
    margin-top: 4px;
  }
</style>

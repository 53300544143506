<script>

  import { createEventDispatcher, tick } from 'svelte';

  export let placeholderText = "*Your Name"

  export let name = ""
  export let isValid = false
  export let rtl = false
  export let leaveSpaceForError = true
  export let includeErrors = false

  let dispatch = createEventDispatcher();

  async function handleValidation(){
    await tick()
    // is longer than 2 characters and has a space
    isValid = name.length > 1
    dispatch('change')
  }
</script>

<div class="input-wrapper text-sm">
  <input type="text"
    class={`${(rtl) ? 'rtl' : ''} ${(!leaveSpaceForError) ? 'mb-2' : ''}`}
    on:input={handleValidation}
    on:change={handleValidation}
    placeholder={placeholderText}
    bind:value={name}
  />
  {#if includeErrors}
    {#if leaveSpaceForError}
      <div class="error-wrapper">
        {#if !isValid && name.length > 1}
          Please include a valid name.
        {/if}
      </div>
    {:else}
      {#if !isValid && name.length > 1}
        <div class="error-wrapper">
            {''}
        </div>
      {/if}
    {/if}
  {/if}
</div>


<style>

  .input-wrapper{
    width: 100%;
  }

  input[type=text]{
    width: calc(100% - 0px);
    border: none;
    height: 32px;
    background-color: white;
    border: 1px solid #C3C3C3;
    padding: 0 5px;
  }

  .error-wrapper{
    width: 100%;
    height: 25px;
    color: #E20303;
    font-size: 12px;
    margin-top: 4px;
  }
</style>

<script>
  import CheckBox from './CheckBox.svelte'
  import { createEventDispatcher } from 'svelte';

  export let terms = '';
  export let isValid = false;
  export let rtl = false;

  const dispatch = createEventDispatcher();

  let checked = false;

  function toggle(){
    checked = !checked;
    isValid = checked
    dispatch('change', checked)
  }
</script>
<div class="main flex flex-row w-full" on:click={toggle}>
  <div class="w-4">
  <CheckBox
    checked={checked}
    bind:value={checked}
  />
</div>
  <div
    class={`ml-2 terms ${(rtl) ? 'rtl' : ''}`}>
    {terms}
    <slot/>
  </div>
</div>

<style>
  .main{
  }
</style>

<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let checked = false;
  export let id;

  function toggleCheck() {
    checked = !checked;
    dispatch("click", id);
  }
</script>
<div class="main" on:click={toggleCheck}>
  {#if (checked)}
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" width="11.534" height="8.453" viewBox="0 0 11.534 8.453">
      <path id="Union_1" data-name="Union 1" d="M2.444,6.764l.047-.044L0,4.4l1.667-1.55L4.158,5.171,9.717,0l1.816,1.689L4.261,8.453Z"/>
    </svg>
  {/if}
</div>

<style>
  .checkmark {
    position: absolute;
    top: 2px;
    left: 1px;
  }
  .main {
    height: 13px;
    width: 13px;
    background-color: white;
    border: 1px solid #b7b7b7;
    color: black;
    display: inline-block;
    position: relative;
  }
</style>

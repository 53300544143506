
// project: f17160-gaylord-excipient-dmbv
// new measurement id: G-90KEWL1TKS 
// secret: 0JtUZFugQcuvSci3khqrlw
// ga: 439783548
import GaylordWrapper from '../components/forms/clients/GaylordWrapper.svelte'
// demo-porsche-taycan (porsche-taycan-9fur)
// 
// 

import generateUniqueSessionId from '../lib/generateUniqueSessionId.js'

const buttonBackground = "#134382";
const buttonText = "#ffffff";
const mainUrl = 'https://gaylordchemical-events.com';
const slug = "ceba689163ff99b9603b1f5c20a1b305|fde99a9043fbdc7c6fbc74958dfb584fe112cbc2f2cb07f18c7d8b6dbbb602c6c0e07c331d92eda32c12d22b88c5a4e87e4c55771779ea6fa8c55c3f71d9e60a3a";
const buttons = [
    {title: "Variability"},
    {title: "Traceability"},
    {title: "Regulatory Compliance"}
]
//.sort(() => Math.random() - 0.5);
const imageFolderName = "gaylord";

const checkValue = (value, defaultValue) => {
  return (typeof(value) !== 'undefined') ? value : defaultValue
}
const darkMode = false
const styleParams = {
  mainColor: buttonBackground,
  secondaryColor: buttonText,
}
const style =           {
  // basic colors
  brandColor: checkValue(styleParams.brandColor, '#ffffff'),
  secondaryBrandColor: checkValue(styleParams.mainColor, 'lightblue'),
  chatBackgroundColor: (darkMode) ? '#000000' : '#ffffff', // the background color in the actual chat
  // cards
  cardBackground: (darkMode) ? '#333333' : '#ebe8e8', // the card background
  cardTextColor: checkValue(styleParams.cardTextColor, (darkMode) ?  '#ffffff' : '#000000'),// cards
  borderRadius: '8px', // cards + buttons
  imagesHaveBorders: true,
  // Users
  userTextColor: '#ffffff',// user options
  userBackgroundColor: "#959595",
  // Text Input
  UIButtonBackgroundColor: '#959595', // textInput send` button
  UIButtonStrokeColor: 'white', // textInput send button
  textInputColor: '#666666', // textInput options
  textInputBorderRadius: '16px', // textInput options
  typingIndicator: (darkMode) ? 'rgb(200,200,200)': 'rgb(55, 55, 55)',
}

const config = {
  darkMode: false,
  url: 'https://adchat-ai.uc.r.appspot.com/b',
  productionMode: true,
  headerLogoURL: "https://gaylordchemical-events.com",
  headerButtonPostback: false,
  language: 'EN',
  logoURL: `images/${imageFolderName}/header.png`,
  // rightLogoURL: `images/${imageFolderName}/logo-right.jpg`,
  roundLogo: false,
  headerBackgroundPosition: '16px 50%',
  headerBackgroundColor: 'white',
  introPostback: buttons[0].title,
  firstFrameClickShouldChat: true,
  chatHeightOffset: 80,
  slugMode: true,
  slug: slug,
  firstFrameExitMode: false,
  // firstFrameExitURL: mainUrl,
  chatTimingMS: 600,
  imagesHaveBorders: true,
  ySpaceBetweenCards: '6px',
  sessionId: generateUniqueSessionId(),
  platform: 'landing-page-en',
  hasInput: false,
  conversationHasInput: false,
  version: '1.0',
  headerExitURL: mainUrl,
  inputConfig: {
    textInputWidth: '80%',
    promptCopy: "Ask me about Denali..."
  },
  firstFrame: {
    inputConfig: {
      textInputWidth: '80%',
      promptCopy: "Ask me about Denali..."
    },
  },
  pixels: {},
  exits: {
  },
  exitRedirects: {
  },
  simulatedConversation: [
      {
          type: 'image',
          src: `./images/${imageFolderName}/first-frame.mp4`,
      },
      {
          type: 'brandResponse',
          text: "Safer Solutions for the Life Sciences.",
      },
      {
          type: 'quickReplies',
          text: "What key regulatory factors concern you most? 👇",
          size: 'large',
          buttons: buttons
      }
  ],
} 





const app = new GaylordWrapper({
	target: document.body,
	props: {
		style: style,
		config: config,
	}
});

export default app;

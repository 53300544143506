<script lang="">
	import TemplateSocialView from "../../../views/TemplateSocialView.svelte";
    
    import GaylordForm from "./GaylordForm.svelte";
    export let style;
    export let config;

    let formOpen = false
    let submissionPhrase = null;


    function handleCloseModal(event) {
        formOpen = false
        if(event.detail !== false){
            submissionPhrase = "👍"
        }
    }

    function handleInstruction(event){
        console.log('instruction', event.detail)
        formOpen = (event.detail === 'openModal')
    }

</script>
<div class="w-full h-full flex justify-center items-center">
<div class="h-full w-full max-w-[500px] relative">
    <TemplateSocialView 
        config={config} 
        style={style}
        on:instruction={handleInstruction}
        submissionPhrase={submissionPhrase}
    />
    {#if formOpen}
        <div class="absolute top-0 left-0 w-full h-full bg-black/70">
            <GaylordForm 
                on:closeModal={handleCloseModal}
                title="To contact the team, fill out the form below and one of the team will be in touch."
            />
        </div>
    {/if}
</div>
</div>
<script lang="ts">
    // curl '<url>' -X POST -H 'User-Agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:125.0) Gecko/20100101 Firefox/125.0' -H 'Accept: */*' -H 'Accept-Language: en-US,en;q=0.5' -H 'Accept-Encoding: gzip, deflate, br' -H 'Referer: http://localhost:5002/' -H 'Content-Type: application/json' -H 'Content-Length: 66' -H 'Origin: http://localhost:5002' -H 'Connection: keep-alive' -H 'Sec-Fetch-Dest: empty' -H 'Sec-Fetch-Mode: cors' -H 'Sec-Fetch-Site: same-site'
    import NameInput from "../../../components/forms/NameInput.svelte";
    import PhoneInput from "../../../components/forms/PhoneInput.svelte";
    import { tick, createEventDispatcher } from "svelte";
	import EmailInput from "../EmailInput.svelte";
    import isEmailValid from '../../../lib/emailValidator.js'
	import TermsCheckBox from "../TermsCheckBox.svelte";
    
    export let title = '';
    
    let name = "";
    let nameIsValid = false;
    let company = "";
    let companyIsValid = false;
    let email = "";
    let emailIsValid = false;
    let phoneNumber = "";
    let phoneNumberIsValid = false;
    let termsValid = false;
    
    let acceptsPrivacyPolicy = false;
    let serverUrl = "https://adchat.ai/services/generic/index.php" ;
    let dispatch = createEventDispatcher();

    async function handleValidation() {
        await tick();
        nameIsValid = name.length > 5;
        companyIsValid = company.length > 5;
        emailIsValid = isEmailValid(email);
        phoneNumberIsValid = phoneNumber.length > 7;
    }

    function handleSubmit() {
        // create form data object
        const formData = {
            name,
            phone: phoneNumber,
            company,
            email,
            formId: "1zZxRhlWmBdQJA4zmOG2l9G0K5ehzQl1ZVQdn5VgnMAo"
        }
        // send form data to serverUrl
        fetch(serverUrl, {
            method: "POST",
            // allow cors
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData)
        }).then(response => {
            if (response.ok) {
                console.log(response)
                console.log("Form submitted successfully");
                handleCloseModal();
            } else {
                console.log("Form submission failed");
            }
        }).catch(error => {
            console.log("Form submission failed");
        });
        
    }

    function handleCloseModal() {
        dispatch("closeModal", true)
    }

</script>
<div class="relative w-full h-full p-4">
    <div class="absolute top-8 right-8 s-4 text-black hover:underline cursor-pointer"
    on:click={() => dispatch("closeModal", false)}>X</div>
    <div class="bg-white text-black w-full h-full flex flex-col items-start rounded-md p-4 gap-2 border border-solid border-gray-300">
        <img
            class="w-auto h-[40px] my-4"
            src="/images/gaylord/header.png"/>
        <h1 class="text-md mt-4 mb-2">{title}</h1>
        <NameInput
            on:change={handleValidation}
            bind:name={name}
            bind:isValid={nameIsValid}
            placeholderText="Name*"
        />
        <NameInput
            on:change={handleValidation}
            bind:name={company}
            bind:isValid={companyIsValid}
            placeholderText="Company*"
        />
        <EmailInput
            on:change={handleValidation}
            bind:email={email}
            bind:isValid={emailIsValid}
            placeholderText="Email*"
        />
        <PhoneInput
            on:change={handleValidation}
            bind:phoneNumber={phoneNumber}
            bind:isValid={phoneNumberIsValid}
            placeholderText="Telephone Number*"
        />
        <TermsCheckBox 
            on:change={handleValidation}
            bind:isValid={termsValid}
            terms={"I consent to Gaylord Chemical, L.L.C. using my information to contact me by telephone and email about my enquiry and/or related products and services."}
        />
        <TermsCheckBox 
            on:change={handleValidation}
            bind:isValid={acceptsPrivacyPolicy}
        >
            I accept the <a href="https://www.gaylordchemical.com/privacy-policy/" target="_new">Privacy Policy.</a>
        </TermsCheckBox>
        <button class="bg-[#134382] text-white p-2 rounded-md disabled:text-white/70 border-none m-4 px-4" 
            disabled={!nameIsValid || !phoneNumberIsValid || !emailIsValid || !companyIsValid || !termsValid || !acceptsPrivacyPolicy}
            on:click={handleSubmit}
            >
            Submit
        </button>
    </div>
</div>

<script>

  import { createEventDispatcher, tick } from 'svelte';
  import isEmailValid from '../../lib/emailValidator.js'

  export let placeholderText = "*Your Email"
  export let isValid;
  export let rtl = false;
  export let leaveSpaceForError = true;
  export let errorMessage = "Please include a valid email address.";
  export let includeErrors = false;

  let showEmailError = false;
  export let email = '';

  let dispatch = createEventDispatcher();

  async function handleValidation(){
    await tick()
    isValid = isEmailValid(email)
    showEmailError = !isValid && email.length > 0
    dispatch('change', {isValid: isValid, value: email});
  }

</script>

<div class="input-wrapper">
  <input type="text"
    class={`${(rtl) ? 'rtl text-sm' : 'text-button'} ${(!leaveSpaceForError) ? 'mb-2' : ''}`}
    on:input={handleValidation}
    on:change={handleValidation}
    placeholder={placeholderText}
    bind:value={email}
  />
  {#if leaveSpaceForError && includeErrors}
    <div class="error-wrapper">
      {#if showEmailError}
        {errorMessage}
      {/if}
    </div>
  {:else}
    {#if showEmailError}
      <div class="error-wrapper">
        {errorMessage}
      </div>
    {/if}
  {/if}
</div>

<style>

  .input-wrapper{
    width: 100%;
  }

  input[type=text]{
    width: calc(100% - 0px);
    border: none;
    height: 32px;
    background-color: white;
    border: 1px solid #C3C3C3;
    padding: 0 5px;
  }

  .error-wrapper{
    width: 100%;
    height: 25px;
    color: #E20303;
    font-size: 12px;
    margin-top: 4px;
  }

</style>
